.P10 {
  padding: 62pt 71pt 85pt 71pt;
  display: flex;
  justify-content: end;
  align-items: center;
  flex-direction: column;
  background-image: url(./common/end-background.webp);
  background-size: cover;
}

.P10--logo {
  margin: 0 0 26pt 0 ;
}

.P10--info {
  margin: 0 0 5pt 0;
  font-size: 14pt;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: var(--black);
}

.P10--info.highlight {
  font-weight: 500;
}
