.P7 {
  display: flex;
  flex-direction: column;
  padding: 62pt 71pt 79pt 71pt;
  background-image: url(./common/people2@3x.webp);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 195pt 140pt;
  background-color: var(--off-white);
}

.P7--markets-container {
  margin: 20pt calc(108pt - 71pt) 0 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between; 
}

.P7--market-container {
  display: grid;
  flex: 1;
  grid-template-columns: 0fr auto;
}

.P7--market-dot {
  margin: 2pt 0 0 0 ;
  grid-column: 1;
  width: 8pt;
  height: 15pt;
  object-fit: contain;
  border-radius: 3px;
}

.P7--market-dot.green {
  background-color: var(--dark-green-blue);
}
.P7--market-dot.blue {
  background-color: var(--dusk-blue);
}

.P7--market-title {
  grid-column: 2;
  margin: 0 0 5pt 6pt;
  font-size: 14pt;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: var(--black);
}

.P7--market-info {
  grid-column: 1 / span 2;
  font-size: 14pt;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: var(--black);
}