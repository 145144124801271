* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}

.Objektbeskrivning--loading-title {
  margin-top: 100px;
  font-size: large;
}

.Objektbeskrivning--container {
  display: grid;
  grid-template-columns: 0fr 1fr;
}

@media print {
  .Objektbeskrivning--container {
    display: block;
  }
}