.P9 {
  padding: 62pt 71pt 79pt 71pt;
  display: flex;
  flex-direction: column;
}


.P9--plaritning-container {
  width: 100%;
  height: 550pt;
}

.P9--plaritning-container > img {
  width: 100%;
  height: 100%;
}
