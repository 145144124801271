.InfoGrid {
  display: grid;
  margin-bottom: 24pt;
  grid-template-columns: 0fr 1fr;
}

.InfoGrid--nomargin {
  display: grid;
  grid-template-columns: 0fr 1fr;
}

.InfoGrid--item.row {
  grid-column: 1 / span 2;
}

.InfoGrid--item.title {
  grid-column: 1 / span 2;
  height: 57px;
  margin: 0 0 20pt 0;
  font-size: 45pt;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.02;
  letter-spacing: normal;
  text-align: left;
  color: var(--black);
}

.InfoGrid--item.left {
  font-size: 18pt;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: var(--black);
  margin-right: 25pt;
  white-space: nowrap;
}

.InfoGrid--item.right {
  font-size: 18pt;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: var(--black);
}

.InfoGrid--item.details {
  margin-top: 2pt;
  font-size: 14pt;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: var(--black);
}

.InfoGrid--item {
  margin-bottom: 12pt;
}
