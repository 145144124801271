body {
  margin: 0;
  padding: 0;
  background-color: #fafafa;
}

@font-face {
    font-family: 'Circular';
    font-display: swap;
    font-weight: 900;
    src: url(assets/fonts/Circular-Black.ttf) format('truetype');
  }
  @font-face {
    font-display: swap;
    font-family: 'Circular';
    font-style: italic;
    font-weight: 900;
    src: url(assets/fonts/Circular-BlackItalic.ttf) format('truetype');
  }
  @font-face {
    font-display: swap;
    font-family: 'Circular';
    font-weight: 700;
    src: url(assets/fonts/lineto-circular-bold.woff2) format('woff2'),
      url(assets/fonts/lineto-circular-bold.woff) format('woff'),
      url(assets/fonts/Circular-Bold.ttf) format('truetype'),
      url(assets/fonts/lineto-circular-bold.eot) format('eot');
  }
  @font-face {
    font-display: swap;
    font-family: 'Circular';
    font-style: italic;
    font-weight: 700;
    src: url(assets/fonts/lineto-circular-bold.woff2) format('woff2'),
      url(assets/fonts/lineto-circular-bold.woff) format('woff'),
      url(assets/fonts/Circular-BoldItalic.ttf) format('truetype'),
      url(assets/fonts/lineto-circular-bold.eot) format('eot');
  }
  @font-face {
    font-display: swap;
    font-family: 'Circular';
    font-weight: normal;
    src: url(assets/fonts/lineto-circular-book.woff2) format('woff2'),
      url(assets/fonts/lineto-circular-book.woff) format('woff'),
      url(assets/fonts/Circular-Book.ttf) format('truetype'),
      url(assets/fonts/lineto-circular-book.eot) format('eot');
  }
  @font-face {
    font-display: swap;
    font-family: 'Circular';
    font-style: italic;
    font-weight: normal;
    src: url(assets/fonts/lineto-circular-book.woff2) format('woff2'),
      url(assets/fonts/lineto-circular-book.woff) format('woff'),
      url(assets/fonts/Circular-BookItalic.ttf) format('truetype'),
      url(assets/fonts/lineto-circular-book.eot) format('eot');
  }
  @font-face {
    font-display: swap;
    font-family: 'Circular';
    font-weight: 500;
    src: url(assets/fonts/lineto-circular-medium.woff2) format('woff2'),
      url(assets/fonts/lineto-circular-medium.woff) format('woff'),
      url(assets/fonts/Circular-Medium.ttf) format('truetype'),
      url(assets/fonts/lineto-circular-medium.eot) format('eot');
  }
  @font-face {
    font-display: swap;
    font-family: 'Circular';
    font-style: italic;
    font-weight: 500;
    src: url(assets/fonts/lineto-circular-medium.woff2) format('woff2'),
      url(assets/fonts/lineto-circular-medium.woff) format('woff'),
      url(assets/fonts/Circular-MediumItalic.ttf) format('truetype'),
      url(assets/fonts/lineto-circular-medium.eot) format('eot');
  }

:root {
    --black: #282828;
    --dark-peach: #d87762;
    --cerulean: #0269ce;
    --pinkish-grey: #c7bbaf;
    --butterscotch: #e7b558;
    --light-peach: #edd6ca;
    --brownish-pink: #c48669;
    --blush: #e3928f;
    --dusk-blue: #275390;
    --white: #fff;
    --pale: #e9e0d7;
    --dark-peach: #db8053;
    --dark-sky-blue: #3e83e3;
    --light-grey-blue: #aab4be;
    --taupe: #b0a08c;
    --dark-green-blue: #14594c;
    --dark-blue-grey: #172d44;
    --brown-grey: #a1a1a1;
    --very-light-pink: #f2f2f2;
    --white: #fbfbfb;
    --brownish-grey: #81755e;
    --light-forest-green: #4f8462;
    --dark: #22242c;
    --black: #000;
    --off-white: #f0f0e8;
    --brown-grey: #7b7b7b;
    --silver: #caded2;
    --pale-blue: #dfe7ef;
    --soft-blue: #6197e2;
  }
  