@page {
  size: A4;
  margin: 0;
}

.Page {
  position: relative;
  overflow: hidden;
  width: 21cm;
  height: 29.7cm;
  margin: 1cm;
  border: 1px #d3d3d3 solid;
  border-radius: 5px;
  background: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.Page--overflow-detector-outer {
  width: 100%;
  height: 100%;
}

.Page--overflow-detector-inner {
  display: table;
  height: inherit;
  width: inherit;
}

.Page--content {
  height: 100%;
  width: 100%;
}

@media print {
  .Page {
    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    width: 100vw;
    height: 100vh;
    page-break-after: always;
  }
}
