.P1 {
  padding: 71pt 71pt 87pt 71pt;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
}

.P1--splash-container {
  width: 100%;
  height: 482pt;
  margin: 0 0 34pt;
  border-radius: 15pt;
  overflow: hidden;
}

.P1--splash-picture {
  height: 100%;
  width: 100%;
}

.P1--gata {
  font-size: 40pt;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.15;
  letter-spacing: normal;
  color: var(--black);
  text-align: center;
}

.P1--gata.mindre {
  font-size: 36pt;
}
.P1--gata.jätteliten {
  font-size: 26pt;
}

.P1--omrade {
  margin-top: 5pt;
  font-size: 25pt;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: normal;
  color: var(--brown-grey);
  text-align: center;
}

.P1--logo-container {
  margin-top: auto;
}
