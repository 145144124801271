* {
  font-family: 'Circular';
}

.Typography--header {
  margin: 0 0 20pt 0;
  font-size: 45pt;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.02;
  letter-spacing: normal;
  text-align: left;
  color: var(--black);
}

.Typography--title {
  margin: 0 0 8pt 0;
  font-size: 23pt;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.12;
  letter-spacing: normal;
  text-align: left;
  color: var(--black);
}

.Typography--content {
  margin: 0 0 40pt 0;
  font-size: 18pt;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: var(--black);
  white-space: pre-wrap;
}

.Typography--smallcontent {
  margin: 0 0 12pt 0;
  font-family: Circular;
  font-size: 13pt;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: var(--black);
}

.nomargin {
  margin: 0 0 5pt 0;
}

.underlined {
  text-decoration: underline;
  font-weight: 500;
}
