.P6 {
  padding: 62pt 71pt 79pt 71pt;
  background-image: url(./common/plant@3x.webp);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 195pt 140pt;
  background-color: var(--silver);
}

.P6--info-content-container {
  margin: 0 calc(132pt - 71pt) 5pt 0;
  display: flex;
  white-space: pre-wrap;
}

.P6--info-freetext {
  margin: 0 calc(132pt - 71pt) 2pt 0;
  font-size: 14pt;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: var(--black);
  white-space: pre-wrap;
}

.P6--info-key {
  margin: 0;
  font-size: 14pt;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: var(--black);
  white-space: pre-wrap;
}

.P6--info-value {
  font-weight: normal;
}
