.P8 {
  padding: 62pt 71pt 79pt 71pt;
  display: flex;
  flex-direction: column;
}



.P8--foton-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 29pt;
}

.P8--foto-landscape > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.P8--foto-portrait > img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.P8--foto-landscape {
  grid-column: span 2;
  aspect-ratio: 1.4;
  overflow: hidden;
}

.P8--foto-portrait {
  overflow: hidden;
  aspect-ratio: 1/1.4;
}
