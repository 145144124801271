.P2 {
  padding: 62pt 71pt 79pt 71pt;
  display: flex;
  flex-direction: column;
}

.P2--expander {
  flex: 1;
}

.P2--link-container {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  margin-bottom: 28pt;
}

.P2--link {
  padding: 10.3pt 19.3pt 11.8pt 18pt;
  border-radius: 15pt;
  background-color: var(--cerulean);
  display: flex;
  align-items: baseline;
}

.P2--link-text {
  font-size: 14pt;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: var(--white);
}

.P2--link-icon {
  margin-left: 11pt;
}

.P2--backup-contact {
  margin-top: 12pt;
  overflow: hidden;
  border-radius: 10pt;
  background-color: var(--off-white);
  display: flex;
}

.P2--contact {
  border-radius: 10pt;
  overflow: hidden;
  background-color: var(--light-peach);
  display: flex;
}

.P2--contact-info-container {
  padding: 13pt 0 13pt 21pt;
  display: flex;
  flex-direction: column;
}

.P2--contact-photo-container {
  position: relative;
  width: 108pt;
  overflow: hidden;
}

.P2--contact-photo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.P2--contact-photo > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.P2--contact-info-title {
  font-size: 12pt;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: var(--blush);
}

a.P2--contact-info-title {
  text-decoration: none;
}

.P2--contact-info-name {
  margin: 2pt 0 2pt 0;
  font-size: 18pt;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: var(--black);
}
